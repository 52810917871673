import GGSlave from "./integrations/seven-gravity-gateway";
import nativeWebview from "./integrations/webview";
import nativeWKWebview from "./integrations/wkwebview";

const integrator = {
  instance: {} as
    | typeof GGSlave
    | typeof nativeWebview
    | typeof nativeWKWebview,

  init(integrationType: string) {
    if (integrationType === "gravityGateway") {
      this.instance = GGSlave;
    } else if (integrationType === "webview") {
      this.instance = nativeWebview;
    } else if (integrationType === "wkwebview") {
      this.instance = nativeWKWebview;
    } else {
      console.error(`Unknown integration implementation: ${integrationType}`);
    }

    return this.instance?.init();
  },

  requireUserLogin() {
    return this.instance?.requireUserLogin();
  },

  notifyAppLoaded() {
    return this.instance?.notifyAppLoaded();
  },
};

export default integrator;
