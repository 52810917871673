<template>
  <OddinIframe v-if="integrationData" :integration-data="integrationData" />
  <SCAlert
    v-model="errorNotification.active"
    :timeout="null"
    :variant="errorNotification.variant"
    :position="errorNotification.position"
  >
    <template #text>{{ errorNotification.text }}</template>
  </SCAlert>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, reactive } from "vue";
import { useUrlSearchParams } from "@vueuse/core";
import { getConfiguration, login } from "@/api/oddinIntegrator";
import {
  OddinOddsType,
  SevenOddsType,
  type SevenOddsTypeTrait,
  type OddinOddsTypeTrait,
  type OddinIframeIntegrationData,
  type Token,
} from "@/types";
import OddinIframe from "./OddinIframe.vue";
import integrator from "@/services";
import eventBus from "@/utility/eventBus";
import { AlertVariant, AlertPosition, SCAlert } from "@nsftx/seven-components";
import { useStore } from "@/store/";

const { integrationType: integrationTypeSearchParam } =
  useUrlSearchParams("history");
const integrationType =
  integrationTypeSearchParam instanceof Array
    ? integrationTypeSearchParam[0]
    : integrationTypeSearchParam;
const isIntegration = !!integrationType;

const config = ref<Configuration>();
const integrationData = ref<OddinIframeIntegrationData>();
const tenantUuid = ref("");
const lang = ref("");
const integrationToken = ref();
const sevenToken = ref();
const thirdPartyToken = ref<Token>();
const currency = ref("");
const oddsFormat = ref("");
const errorNotification = reactive({
  active: false,
  text: "",
  position: AlertPosition.Bottom,
  variant: AlertVariant.Negative,
});
const authorizationTokenTimeout = ref();
const store = useStore();

interface Configuration {
  language: string;
  darkTheme: boolean;
  brandToken: string;
  currency: string;
  customDomains: Array<string>;
}

interface IntegratorResponseData {
  auth: { token: Token; tpToken: Token };
  currency: { symbol: string };
  locale: { iso1: string };
  tenant: { uuid: string };
  settings: { oddType: SevenOddsType };
}

const setConfiguration = async () => {
  const apiConfig = await getConfiguration(tenantUuid.value, currency.value);

  const {
    darkTheme,
    language,
    brandToken,
    currency: mappedCurrency,
    customDomains,
    error,
    message,
  } = apiConfig;

  config.value = {
    darkTheme,
    language,
    brandToken,
    currency: mappedCurrency,
    customDomains,
  };
  if (error) {
    handleErrorMessage(error, message);
  }
};

const setOddsType = (sevenOddsType: number) => {
  const selectedOddsType = SevenOddsType[sevenOddsType] as SevenOddsTypeTrait;
  const selectedOddinOddType = OddinOddsType[
    selectedOddsType
  ] as OddinOddsTypeTrait;

  oddsFormat.value = selectedOddinOddType;
};

const loginAndSetIntegrationToken = async () => {
  const { token, error, message } = await login(
    tenantUuid.value,
    sevenToken.value,
    lang.value,
    currency.value,
    thirdPartyToken.value
  );

  integrationToken.value = token;
  if (error) {
    handleErrorMessage(error, message);
  }
};

const prepareOddinConfiguration = () => {
  const supportedOddTypes = Object.keys(OddinOddsType).map((key) => {
    const oddinOddsTypeKey = key as OddinOddsTypeTrait;
    return OddinOddsType[oddinOddsTypeKey];
  });

  if (config.value) {
    integrationData.value = {
      token: integrationToken.value,
      brandToken:
        config.value?.brandToken ||
        import.meta.env.VITE_APP_TEMPORARY_BRAND_TOKEN,
      language: config.value.language || lang.value.slice(0, 2),
      currency: config.value.currency,
      darkMode: config.value.darkTheme,
      oddsFormat: oddsFormat.value,
      customDomain: config.value.customDomains,
      supportedOddsFormats: supportedOddTypes,
    };
  }
};

const setupSevenIntegration = async () => {
  const {
    auth: { token, tpToken },
    currency: { symbol: currencySymbol },
    locale: { iso1: iso639locale },
    tenant: { uuid: tenantId },
    settings: { oddType: sevenOddsType },
  } = (await integrator.init(integrationType)) as IntegratorResponseData;

  currency.value = currencySymbol.toLowerCase();
  lang.value = iso639locale;
  sevenToken.value = token;
  tenantUuid.value = tenantId;
  thirdPartyToken.value = tpToken || undefined;

  setListeners();
  notifyAppLoad();
  setOddsType(sevenOddsType);

  if (sevenToken.value) {
    await loginAndSetIntegrationToken();
  }
  await setConfiguration();
  prepareOddinConfiguration();
};

const setListeners = () => {
  eventBus.subscribe(
    "User.AuthorizationChanged",
    handleAuthorizationChangeMessage
  );
  eventBus.subscribe("HandleErrorMessage", handleErrorMessage);
};

const handleAuthorizationChangeMessage = async (data: {
  auth: { token: Token; tpToken: Token };
}) => {
  sevenToken.value = data.auth.token;
  thirdPartyToken.value = data.auth.tpToken || undefined;

  if (sevenToken.value) {
    await loginAndSetIntegrationToken();
  } else {
    errorNotification.active = false;
    integrationToken.value = "";
  }

  if (authorizationTokenTimeout.value) {
    clearTimeout(authorizationTokenTimeout.value);
  }
  checkAndUpdateOddinInstance();
};

function checkAndUpdateOddinInstance() {
  if (!window.oddin?.instance) {
    authorizationTokenTimeout.value = setTimeout(() => {
      checkAndUpdateOddinInstance();
    }, 2000);
  } else {
    window.oddin?.instance.updateConfig({
      token: integrationToken.value,
    });
  }
}

const handleErrorMessage = (error: boolean, message: string) => {
  errorNotification.active = error;
  errorNotification.text = message ? message : store.errorMessage;
};

const loadOddinScript = () => {
  const oddinScript = document.createElement("script");
  oddinScript.setAttribute(
    "src",
    import.meta.env.VITE_APP_ODDIN_INTEGRATION_SCRIPT_URL
  );
  document.head.appendChild(oddinScript);

  oddinScript.onerror = (e) => {
    console.log("Failed to load oddin script", e);
  };

  oddinScript.onload = () => {
    console.info("Loaded oddin script");
  };
};

const notifyAppLoad = () => {
  integrator.notifyAppLoaded();
};

onBeforeMount(async () => {
  loadOddinScript();

  if (isIntegration) {
    setupSevenIntegration();
  }
});
</script>
