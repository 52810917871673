import * as Sentry from "@sentry/vue";
import { type App as AppType } from "vue";

const initializeErrorTracker = (app: AppType) => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.globalHandlersIntegration({
        onunhandledrejection: false,
        onerror: true,
      }),
    ],
    tracePropagationTargets: [
      "localhost",
      import.meta.env.VITE_APP_APP_URL,
      /^\//,
    ],
    environment: import.meta.env.MODE,
    logErrors: true,
    tracesSampleRate: 0.3,
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /gpt\/pubad/,
      /addonsmash\//,
      /tpc.googlesyndication.com/,
      /\/\.netlify/,
    ],
  });

  window.addEventListener("unhandledrejection", (event) => {
    Sentry.captureMessage(event.reason);
  });
};

export default initializeErrorTracker;
