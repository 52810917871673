import { defineStore } from "pinia";

export const useStore = defineStore("store", {
  state: () => ({
    genericErrorMessage:
      "Something went wrong. Please reload or contact customer support",
  }),
  getters: {
    errorMessage: (state) => state.genericErrorMessage,
  },
});
