import { eventBus, prettyPrintJson } from "@/utility";
import {
  type IntegratorInstance,
  type IntegratorInitialResolve,
} from "@/types";

let instance: IntegratorInstance;
let initializationResolve: IntegratorInitialResolve;

export default {
  init() {
    this.setInstance();
    this.setListeners();

    return new Promise((resolve) => {
      initializationResolve = resolve;

      instance?.sendMessage("Slave.Init");
    });
  },

  setInstance() {
    instance = {
      sendMessage: (message: string) => {
        try {
          window.WebAppListener.sendMessage(
            JSON.stringify({
              action: message,
            })
          );
        } catch (err) {
          console.error(
            `Error sending message ${message} to android webview`,
            err
          );
        }
      },
    };
  },

  setListeners() {
    window.onParentMessage = (message: string, payload: { data: unknown }) => {
      console.log(
        `Incoming message from native integrator: ${message}, ${prettyPrintJson(
          payload
        )}`
      );

      if (message === "Slave.Load") {
        initializationResolve(payload.data);
      } else {
        eventBus.publish(message, payload.data);
      }
    };
  },

  requireUserLogin() {
    instance?.sendMessage("User.LoginRequired");
  },

  notifyAppLoaded() {
    instance?.sendMessage("Slave.Loaded");
  },
};
