<template>
  <div id="oddinIframe"></div>
</template>
<script lang="ts">
import { type OddinIframeIntegrationData } from "@/types";
import integrator from "@/services";
import eventBus from "@/utility/eventBus";

interface OddinIframeProps {
  integrationData: OddinIframeIntegrationData | undefined;
}
</script>
<script setup lang="ts">
import { onMounted, onUpdated, toRefs } from "vue";

const props = defineProps<OddinIframeProps>();
const { integrationData } = toRefs(props);

const setOddinConfiguration = () => {
  if (integrationData?.value && window.oddin && !window.oddin?.instance) {
    window.oddin.buildBifrost({
      token: integrationData.value.token,
      brandToken: integrationData.value.brandToken,
      language: integrationData.value.language,
      currency: integrationData.value.currency,
      baseUrl: import.meta.env.VITE_APP_ODDIN_INTEGRATION_BASE_URL,
      oddsFormat: integrationData.value.oddsFormat,
      darkMode: integrationData.value.darkMode,
      customDomain: integrationData.value.customDomain,
      supportedOddsFormats: integrationData.value.supportedOddsFormats,
      contentElement: "#oddinIframe",

      eventHandler: (e: { type: string }) => {
        if (e.type === "REQUEST_SIGN_IN") {
          integrator.requireUserLogin();
        }
        if (e.type === "ERROR") {
          eventBus.publish("HandleErrorMessage", true);
        }
      },
    });
  }
};
onUpdated(() => {
  setOddinConfiguration();
});

onMounted(() => {
  setOddinConfiguration();
});
</script>

<style scoped lang="scss">
#oddinIframe {
  width: 100%;
  min-height: 100vh;
}
</style>
