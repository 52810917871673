export enum OddinOddsType {
  DECIMAL = "DECIMAL",
  AMERICAN = "US",
}

export enum SevenOddsType {
  DECIMAL = 1,
  AMERICAN = 3,
}

export interface OddinIframeIntegrationData {
  token: string;
  brandToken: string;
  language: string;
  currency: string;
  darkMode: boolean;
  oddsFormat: string;
  customDomain: Array<string>;
  supportedOddsFormats: Array<string>;
}

export type SevenOddsTypeTrait = keyof typeof SevenOddsType;
export type OddinOddsTypeTrait = keyof typeof OddinOddsType;

export type EnvironmentConfiguration = {
  baseApiURLs: {
    [key: string]: string;
  };
};

export type Token = string | undefined | null;

export interface IntegratorInstance {
  sendMessage: {
    (message: string): void;
  };
}
export interface IntegratorInitialResolve {
  (payload: unknown): void;
}
