import { createApp, type App as AppType } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import initErrorTracker from "@/utility/errorTracker";

import "@/styles/main.scss";
import "@nsftx/seven-components/style";
import "@nsftx/seven-components/palettes/default";

const app: AppType = createApp(App);

const startApp = () => {
  const isLocalDevelopment = window.location.hostname === "localhost";
  const shouldInitErrorTracker =
    !isLocalDevelopment &&
    import.meta.env.VITE_APP_ERROR_TRACKER_ACTIVE === "true";

  if (shouldInitErrorTracker) {
    initErrorTracker(app);
  }

  app.use(createPinia()).mount("#app");
};

startApp();
